import '../../../css/workSamplesPage/contentOtherSection.css';

export function ContentOtherSection(): JSX.Element {
  return (
    <section className="workSamples-Other-section">
      <div className="wrapper">
        <div className="workSamples-Other-content">
          <div className="workSamples-Other-content-child-1">
            <h4 className="font-color-heading-above h4-Other-content-child-1">
              Sonstiges
            </h4>
          </div>
          <div className="workSamples-Other-content-child-2">coming soon</div>
        </div>
      </div>
    </section>
  );
}
