import '../../../css/mePage/meInfoSection.css';

export function DatenschutzSection(): JSX.Element {
  return (
    <section className="me-info-section">
      <div className="wrapper">
        <h4 className="font-color-heading-above">Datenschutz</h4>

        <div className="me-info-content-1">
          Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und
          Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend kurz
          „Daten“) innerhalb unseres Onlineangebotes und der mit ihm verbundenen
          Webseiten, Funktionen und Inhalte auf. (nachfolgend gemeinsam
          bezeichnet als „Onlineangebot“). Im Hinblick auf die verwendeten
          Begrifflichkeiten, wie verweisen wir auf die Definitionen im Art. 4
          der Datenschutzgrundverordnung (DSGVO).
        </div>

        <div className="me-info-content">
          <div className="me-info-content-1">
            <span className="me-info-span">Arten der verarbeiteten Daten:</span>{' '}
            Bestandsdaten (z.B., Namen, Adressen). - Kontaktdaten (z.B., E-Mail,
            Telefonnummern). - Inhaltsdaten (z.B., Texteingaben, Fotografien,
            Videos). - Nutzungsdaten (z.B., besuchte Webseiten, Interesse an
            Inhalten, Zugriffszeiten). - Meta-/Kommunikationsdaten (z.B.,
            Geräte-Informationen, IP-Adressen).
          </div>
        </div>

        <div className="me-info-content">
          <div className="me-info-content-1">
            <span className="me-info-span">Zweck der Verarbeitung:</span>{' '}
            Zurverfügungstellung des Onlineangebotes, seiner Funktionen und
            Inhalte. - Beantwortung von Kontaktanfragen und Kommunikation mit
            Nutzern. - Sicherheitsmaßnahmen. - Reichweitenmessung/Marketing.
          </div>
        </div>

        <div className="me-info-content">
          <div className="me-info-content-1">
            <span className="me-info-span">Maßgebliche Rechtsgrundlagen:</span>{' '}
            Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen
            unserer Datenverarbeitungen mit. Sofern die Rechtsgrundlage in der
            Datenschutzerklärung nicht genannt wird, gilt Folgendes: Die
            Rechtsgrundlage für die Einholung von Einwilligungen ist Art. 6 Abs.
            1 lit. a und Art. 7 DSGVO, die Rechtsgrundlage für die Verarbeitung
            zur Erfüllung unserer Leistungen und Durchführung vertraglicher
            Maßnahmen sowie Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b
            DSGVO, die Rechtsgrundlage für die Verarbeitung zur Erfüllung
            unserer rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO,
            und die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer
            berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO. Für den
            Fall, dass lebenswichtige Interessen der betroffenen Person oder
            einer anderen natürlichen Person eine Verarbeitung personenbezogener
            Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als
            Rechtsgrundlage.
          </div>
        </div>

        <div className="me-info-content">
          <div className="me-info-content-1">
            <span className="me-info-span">Sicherheitsmaßnahmen:</span> Wir
            treffen nach Maßgabe des Art. 32 DSGVO unter Berücksichtigung des
            Stands der Technik, der Implementierungskosten und der Art, des
            Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der
            unterschiedlichen Eintrittswahrscheinlichkeit und Schwere des
            Risikos für die Rechte und Freiheiten natürlicher Personen,
            geeignete technische und organisatorische Maßnahmen, um ein dem
            Risiko angemessenes Schutzniveau zu gewährleisten; Zu den Maßnahmen
            gehören insbesondere die Sicherung der Vertraulichkeit, Integrität
            und Verfügbarkeit von Daten durch Kontrolle des physischen Zugangs
            zu den Daten, als auch des sie betreffenden Zugriffs, der Eingabe,
            Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des
            Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von
            Betroffenenrechten, Löschung von Daten und Reaktion auf Gefährdung
            der Daten gewährleisen. Ferner berücksichtigen wir den Schutz
            personenbezogener Daten bereits bei der Entwicklung, bzw. Auswahl
            von Hardware, Software sowie Verfahren, entsprechend dem Prinzip des
            Datenschutzes durch Technikgestaltung und durch
            datenschutzfreundliche Voreinstellungen berücksichtigt (Art. 25
            DSGVO).
          </div>
        </div>

        <div className="me-info-content">
          <div className="me-info-content-1">
            <span className="me-info-span">
              Rechte der betroffenen Personen:
            </span>{' '}
            Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob
            betreffende Daten verarbeitet werden und auf Auskunft über diese
            Daten sowie auf weitere Informationen und Kopie der Daten
            entsprechend Art. 15 DSGVO. Sie haben entsprechend. Art. 16 DSGVO
            das Recht, die Vervollständigung der Sie betreffenden Daten oder die
            Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.
            Sie haben nach Maßgabe des Art. 17 DSGVO das Recht zu verlangen,
            dass betreffende Daten unverzüglich gelöscht werden, bzw. alternativ
            nach Maßgabe des Art. 18 DSGVO eine Einschränkung der Verarbeitung
            der Daten zu verlangen. Sie haben das Recht zu verlangen, dass die
            Sie betreffenden Daten, die Sie uns bereitgestellt haben nach
            Maßgabe des Art. 20 DSGVO zu erhalten und deren Übermittlung an
            andere Verantwortliche zu fordern. Sie haben ferner gem. Art. 77
            DSGVO das Recht, eine Beschwerde bei der zuständigen
            Aufsichtsbehörde einzureichen.
          </div>
        </div>

        <div className="me-info-content">
          <div className="me-info-content-1">
            <span className="me-info-span">Widerrufsrecht:</span> Sie haben das
            Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3 DSGVO mit Wirkung
            für die Zukunft zu widerrufen.
          </div>
        </div>

        <div className="me-info-content">
          <div className="me-info-content-1">
            <span className="me-info-span">Widerspruchsrecht:</span> Sie haben
            das Sie können der künftigen Verarbeitung der Sie betreffenden Daten
            nach Maßgabe des Art. 21 DSGVO jederzeit widersprechen. Der
            Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke der
            Direktwerbung erfolgen.
          </div>
        </div>

        <div className="me-info-content">
          <div className="me-info-content-1">
            <span className="me-info-span">Löschung von Daten:</span> Die von
            uns verarbeiteten Daten werden nach Maßgabe der Art. 17 und 18 DSGVO
            gelöscht oder in ihrer Verarbeitung eingeschränkt. Sofern nicht im
            Rahmen dieser Datenschutzerklärung ausdrücklich angegeben, werden
            die bei uns gespeicherten Daten gelöscht, sobald sie für ihre
            Zweckbestimmung nicht mehr erforderlich sind und der Löschung keine
            gesetzlichen Aufbewahrungspflichten entgegenstehen. Sofern die Daten
            nicht gelöscht werden, weil sie für andere und gesetzlich zulässige
            Zwecke erforderlich sind, wird deren Verarbeitung eingeschränkt.
            D.h. die Daten werden gesperrt und nicht für andere Zwecke
            verarbeitet. Das gilt z.B. für Daten, die aus handels- oder
            steuerrechtlichen Gründen aufbewahrt werden müssen. Nach
            gesetzlichen Vorgaben in Deutschland, erfolgt die Aufbewahrung
            insbesondere für 10 Jahre gemäß §§ 147 Abs. 1 AO, 257 Abs. 1 Nr. 1
            und 4, Abs. 4 HGB (Bücher, Aufzeichnungen, Lageberichte,
            Buchungsbelege, Handelsbücher, für Besteuerung relevanter
            Unterlagen, etc.) und 6 Jahre gemäß § 257 Abs. 1 Nr. 2 und 3, Abs. 4
            HGB (Handelsbriefe). Nach gesetzlichen Vorgaben in Österreich
            erfolgt die Aufbewahrung insbesondere für 7 J gemäß § 132 Abs. 1 BAO
            (Buchhaltungsunterlagen, Belege/Rechnungen, Konten, Belege,
            Geschäftspapiere, Aufstellung der Einnahmen und Ausgaben, etc.), für
            22 Jahre im Zusammenhang mit Grundstücken und für 10 Jahre bei
            Unterlagen im Zusammenhang mit elektronisch erbrachten Leistungen,
            Telekommunikations-, Rundfunk- und Fernsehleistungen, die an
            Nichtunternehmer in EU-Mitgliedstaaten erbracht werden und für die
            der Mini-One-Stop-Shop (MOSS) in Anspruch genommen wird.
          </div>
        </div>

        <div className="me-info-content">
          <div className="me-info-content-1">
            <span className="me-info-span">Hosting:</span> Die von uns in
            Anspruch genommenen Hosting-Leistungen dienen der
            Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und
            Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
            Datenbankdienste, Sicherheitsleistungen sowie technische
            Wartungsleistungen, die wir zum Zwecke des Betriebs dieses
            Onlineangebotes einsetzen. Hierbei verarbeiten wir, bzw. unser
            Hostinganbieter Bestandsdaten, Kontaktdaten, Inhaltsdaten,
            Vertragsdaten, Nutzungsdaten, Meta- und Kommunikationsdaten von
            Kunden, Interessenten und Besuchern dieses Onlineangebotes auf
            Grundlage unserer berechtigten Interessen an einer effizienten und
            sicheren Zurverfügungstellung dieses Onlineangebotes gem. Art. 6
            Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO (Abschluss
            Auftragsverarbeitungsvertrag).
          </div>
        </div>

        <div className="me-info-content">
          <div className="me-info-content-1">
            <span className="me-info-span">
              Erhebung von Zugriffsdaten und Logfiles:
            </span>{' '}
            Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer
            berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO
            Daten über jeden Zugriff auf den Server, auf dem sich dieser Dienst
            befindet (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören
            Name der abgerufenen Webseite, Datei, Datum und Uhrzeit des Abrufs,
            übertragene Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp
            nebst Version, das Betriebssystem des Nutzers, Referrer URL (die
            zuvor besuchte Seite), IP-Adresse und der anfragende Provider.
            Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur
            Aufklärung von Missbrauchs- oder Betrugshandlungen) für die Dauer
            von maximal 7 Tagen gespeichert und danach gelöscht. Daten, deren
            weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur
            endgültigen Klärung des jeweiligen Vorfalls von der Löschung
            ausgenommen.
          </div>
        </div>

        <div className="me-info-content">
          <div className="me-info-content-1">
            <span className="me-info-span">Kontaktaufnahme:</span> Bei der
            Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon
            oder via sozialer Medien) werden die Angaben des Nutzers zur
            Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs.
            1 lit. b) DSGVO verarbeitet. Die Angaben der Nutzer können in einem
            Customer-Relationship-Management System („CRM System“) oder
            vergleichbarer Anfragenorganisation gespeichert werden. Wir löschen
            die Anfragen, sofern diese nicht mehr erforderlich sind. Wir
            überprüfen die Erforderlichkeit alle zwei Jahre; Ferner gelten die
            gesetzlichen Archivierungspflichten.
          </div>
        </div>

        <div className="me-info-content">
          <div className="me-info-content-1">
            <span className="me-info-span">
              Einbindung von Diensten und Inhalten Dritter:
            </span>{' '}
            Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer
            berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung
            und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des
            Art. 6 Abs. 1 lit. f. DSGVO) Inhalts- oder Serviceangebote von
            Drittanbietern ein, um deren Inhalte und Services, wie z.B. Videos
            oder Schriftarten einzubinden (nachfolgend einheitlich bezeichnet
            als “Inhalte”). Dies setzt immer voraus, dass die Drittanbieter
            dieser Inhalte, die IP-Adresse der Nutzer wahrnehmen, da sie ohne
            die IP-Adresse die Inhalte nicht an deren Browser senden könnten.
            Die IP-Adresse ist damit für die Darstellung dieser Inhalte
            erforderlich. Wir bemühen uns nur solche Inhalte zu verwenden, deren
            jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der
            Inhalte verwenden. Drittanbieter können ferner so genannte
            Pixel-Tags (unsichtbare Grafiken, auch als „Web Beacons“ bezeichnet)
            für statistische oder Marketingzwecke verwenden. Durch die
            „Pixel-Tags“ können Informationen, wie der Besucherverkehr auf den
            Seiten dieser Website ausgewertet werden. Die pseudonymen
            Informationen können ferner in Cookies auf dem Gerät der Nutzer
            gespeichert werden und unter anderem technische Informationen zum
            Browser und Betriebssystem, verweisende Webseiten, Besuchszeit sowie
            weitere Angaben zur Nutzung unseres Onlineangebotes enthalten, als
            auch mit solchen Informationen aus anderen Quellen verbunden werden.
          </div>
        </div>
      </div>
    </section>
  );
}
