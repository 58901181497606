import { useState } from 'react';
import '../../../../../../css/workSamplesPage/designPatterns/structuralPatterns/facade/demo.css';

class ShopProgram {
  private stateOrderProducts: boolean = false;
  private stateCancelOrder: boolean = false;
  private orderNumber: number | null = null;

  constructor() {
    this.orderNumber = this.assignOrderNumber();
  }

  public setOrderProducts(): void {
    this.stateOrderProducts
      ? (this.stateOrderProducts = false)
      : (this.stateOrderProducts = true);
  }

  public setCancelOrder(): void {
    this.stateCancelOrder
      ? (this.stateCancelOrder = false)
      : (this.stateCancelOrder = true);
  }

  public getOrderProducts(): boolean {
    return this.stateOrderProducts;
  }

  public getCancelOrder(): boolean {
    return this.stateCancelOrder;
  }

  public getOrderNumber(): number | null {
    return this.orderNumber;
  }

  private assignOrderNumber(): number {
    return Math.floor(Math.random() * (100000000 - 0) + 0);
  }
}

class WebApp {
  private stateOrderProducts: ShopProgram = new ShopProgram();

  public setStateCancelOrder(): void {
    this.stateOrderProducts.setCancelOrder();
  }
  public setStateOrderProducts(): void {
    this.stateOrderProducts.setOrderProducts();
  }
  public getOrderNumber(): number | null {
    return this.stateOrderProducts.getOrderNumber();
  }
  public getStateOrderProducts(): boolean {
    return this.stateOrderProducts.getOrderProducts();
  }
  public getStateCancelOrder(): boolean {
    return this.stateOrderProducts.getCancelOrder();
  }
}

class MobileApp {
  private stateOrderProducts: ShopProgram = new ShopProgram();

  public setStateCancelOrder(): void {
    this.stateOrderProducts.setCancelOrder();
  }
  public setStateOrderProducts(): void {
    this.stateOrderProducts.setOrderProducts();
  }
  public getOrderNumber(): number | null {
    return this.stateOrderProducts.getOrderNumber();
  }
  public getStateOrderProducts(): boolean {
    return this.stateOrderProducts.getOrderProducts();
  }
  public getStateCancelOrder(): boolean {
    return this.stateOrderProducts.getCancelOrder();
  }
}

class VoiceControl {
  private stateOrderProducts: ShopProgram = new ShopProgram();

  public setStateCancelOrder(): void {
    this.stateOrderProducts.setCancelOrder();
  }
  public setStateOrderProducts(): void {
    this.stateOrderProducts.setOrderProducts();
  }
  public getOrderNumber(): number | null {
    return this.stateOrderProducts.getOrderNumber();
  }
  public getStateOrderProducts(): boolean {
    return this.stateOrderProducts.getOrderProducts();
  }
  public getStateCancelOrder(): boolean {
    return this.stateOrderProducts.getCancelOrder();
  }
}

export function Demo(): JSX.Element {
  const [webApp] = useState<WebApp>(new WebApp());
  const [webAppOrderProductsState, setWebAppOrderProductsState] =
    useState<boolean>(webApp.getStateOrderProducts());
  const [webAppCancelOrderState, setWebAppCancelOrderState] = useState<boolean>(
    webApp.getStateCancelOrder()
  );

  const [mobileApp] = useState<MobileApp>(new MobileApp());
  const [mobileAppOrderProductsState, setMobileAppOrderProductsState] =
    useState<boolean>(mobileApp.getStateOrderProducts());
  const [mobileAppCancelOrderState, setMobileAppCancelOrderState] =
    useState<boolean>(mobileApp.getStateCancelOrder());

  const [voiceControl] = useState<VoiceControl>(new VoiceControl());
  const [voiceControlOrderProductsState, setVoiceControlOrderProductsState] =
    useState<boolean>(voiceControl.getStateOrderProducts());
  const [voiceControlCancelOrderState, setVoiceControlCancelOrderState] =
    useState<boolean>(voiceControl.getStateCancelOrder());

  function clickWebAppOrderProductsState(): void {
    webApp.setStateOrderProducts();
    setWebAppOrderProductsState(webApp.getStateOrderProducts());
  }

  function clickWebAppCancelOrderState(): void {
    webApp.setStateCancelOrder();
    setWebAppCancelOrderState(webApp.getStateCancelOrder());
  }

  function clickMobileAppOrderProductsState(): void {
    mobileApp.setStateOrderProducts();
    setMobileAppOrderProductsState(mobileApp.getStateOrderProducts());
  }

  function clickMobileAppCancelOrderState(): void {
    mobileApp.setStateCancelOrder();
    setMobileAppCancelOrderState(mobileApp.getStateCancelOrder());
  }

  function clickVoiceControlOrderProductsState(): void {
    voiceControl.setStateOrderProducts();
    setVoiceControlOrderProductsState(voiceControl.getStateOrderProducts());
  }

  function clickVoiceControlCancelOrderState(): void {
    voiceControl.setStateCancelOrder();
    setVoiceControlCancelOrderState(voiceControl.getStateCancelOrder());
  }

  return (
    <section className="designPatterns-facade-demo-section">
      <div className="wrapper">
        <div className="designPatterns-facade-demo-content">
          <span className="designPatterns-facade-demo-slogan">
            Als Beispiel für die Eingabe der Clients dienen (Web App, Handy App,
            Sprachsteuerung) und als Beispiel für die Verarbeitung der
            Subsysteme dienen (Rechnungsstelle, Lager, Versand, e-mail System).
          </span>
          <span className="designPatterns-facade-demo-slogan">
            Als Demo gibt es nur 2 Funktionen in den Clients. Diese
            Client-Klassen müssen dann bei Änderungen am System (z.B. sich das
            Versandunternehmen ändert) nicht mehr einzeln geändert werden,
            sondern nur noch die Facade-Klasse ShopProgram.
          </span>
          <span className="designPatterns-facade-demo-slogan">
            <span className="designPatterns-facade-demo-slogan_2">WebApp:</span>
            <span className="designPatterns-facade-demo-slogan_3">
              Bestellnummer: {webApp.getOrderNumber()}
            </span>
            <span className="designPatterns-facade-demo-slogan_3">
              Produkte bestellt: {webAppOrderProductsState ? 'ja' : 'nein'}
            </span>
            <span className="designPatterns-facade-demo-slogan_3">
              Bestellung storniert: {webAppCancelOrderState ? 'ja' : 'nein'}
            </span>
            <span
              onClick={clickWebAppOrderProductsState}
              className="designPatterns-facade-demo-slogan_4 links-designPatterns"
            >
              - Produkte Status ändern
            </span>
            <span
              onClick={clickWebAppCancelOrderState}
              className="designPatterns-facade-demo-slogan_4 links-designPatterns"
            >
              - Bestellung Status ändern
            </span>
          </span>

          <span className="designPatterns-facade-demo-slogan">
            <span className="designPatterns-facade-demo-slogan_2">
              MobileApp:
            </span>
            <span className="designPatterns-facade-demo-slogan_3">
              Bestellnummer: {mobileApp.getOrderNumber()}
            </span>
            <span className="designPatterns-facade-demo-slogan_3">
              Produkte bestellt: {mobileAppOrderProductsState ? 'ja' : 'nein'}
            </span>
            <span className="designPatterns-facade-demo-slogan_3">
              Bestellung storniert: {mobileAppCancelOrderState ? 'ja' : 'nein'}
            </span>
            <span
              onClick={clickMobileAppOrderProductsState}
              className="designPatterns-facade-demo-slogan_4 links-designPatterns"
            >
              - Produkte Status ändern
            </span>
            <span
              onClick={clickMobileAppCancelOrderState}
              className="designPatterns-facade-demo-slogan_4 links-designPatterns"
            >
              - Bestellung Status ändern
            </span>
          </span>

          <span className="designPatterns-facade-demo-slogan">
            <span className="designPatterns-facade-demo-slogan_2">
              VoiceControl:
            </span>
            <span className="designPatterns-facade-demo-slogan_3">
              Bestellnummer: {voiceControl.getOrderNumber()}
            </span>
            <span className="designPatterns-facade-demo-slogan_3">
              Produkte bestellt:{' '}
              {voiceControlOrderProductsState ? 'ja' : 'nein'}
            </span>
            <span className="designPatterns-facade-demo-slogan_3">
              Bestellung storniert:{' '}
              {voiceControlCancelOrderState ? 'ja' : 'nein'}
            </span>
            <span
              onClick={clickVoiceControlOrderProductsState}
              className="designPatterns-facade-demo-slogan_4 links-designPatterns"
            >
              - Produkte Status ändern
            </span>
            <span
              onClick={clickVoiceControlCancelOrderState}
              className="designPatterns-facade-demo-slogan_4 links-designPatterns"
            >
              - Bestellung Status ändern
            </span>
          </span>
        </div>
      </div>
    </section>
  );
}
