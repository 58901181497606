import '../../../../../../css/workSamplesPage/designPatterns/behavioralPatterns/command/diagramm.css';
import allgemein from '../../../../../../public/designPatterns/command/allgemein.svg';
import demo from '../../../../../../public/designPatterns/command/demo.svg';

export function Diagramm(): JSX.Element {
  return (
    <section className="designPatterns-command-diagramm-section">
      <div className="wrapper">
        <div className="designPatterns-command-diagramm-content">
          <span className="designPatterns-command-diagramm-slogan">
            UML Diagramm (Command Pattern allgemein)
          </span>
          <img
            className="designPatterns-command-diagramm-logo"
            src={allgemein}
            alt="designPatterns-command-diagramm"
            width="265"
            height="179"
          ></img>
          <span className="designPatterns-command-diagramm-slogan-2">
            UML Diagramm (Command Pattern Demo)
          </span>
          <img
            className="designPatterns-command-diagramm-logo-2"
            src={demo}
            alt="designPatterns-command-diagramm"
            width="265"
            height="283"
          ></img>
        </div>
      </div>
    </section>
  );
}
