import { ApiReturn, ApiContactForm } from '../interfaces/APIDataModel';

export abstract class RestApiMailer {
    static formUrl: string = 'https://www.jens-geyer.de/mailer/api.php?token/hgfd5tfdd42www33000kjhgtres2233246';

    static async readForm(sendFormName: string, sendFormMail: string, sendFormMessage: string): Promise<ApiReturn<ApiContactForm>> {

        let errorText: string = '';
        const urlResponseForm: string = this.formUrl;
        const responseForm: void | Response = await fetch(urlResponseForm,
            {
                method: 'POST',
                body: JSON.stringify({
                    name: sendFormName,
                    mail: sendFormMail,
                    message: sendFormMessage
                })
            })
            .catch(function(error) {
                errorText = error;
              });

        
        if (responseForm && responseForm.ok === true && responseForm.status === 200) {
            try {
                const content: ApiContactForm | undefined = await responseForm.json();
                return { error: '', content: content};
              } catch(error) {
                return { error: errorText };
              }
        }
        
        if (responseForm) {
            return { error: responseForm.statusText };
        }

        return { error: errorText };
    }
}