import '../css/footer.css';

export interface InfoBoxesSectionProps {
  clickMenu: (value: string) => void;
}

export function Footer(props: InfoBoxesSectionProps): JSX.Element {
  function scrollToTop(): void {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  function clickMenu(value: string): void {
    props.clickMenu(value);
  }

  return (
    <footer className="page-footer wrapper-min-width">
      <div className="wrapper">
        <nav className="footer-navigation">
          <ul className="footer-navigation-list">
            <li>
              <span
                className="page-footer-span"
                onClick={clickMenu.bind('', 'contactPage')}
              >
                Impressum
              </span>
            </li>
            <li>
              <span
                className="page-footer-span"
                onClick={clickMenu.bind('', 'datenschutz')}
              >
                Datenschutz
              </span>
            </li>
            <li>
              <div className="footer-scrollToTop">
                <span onClick={scrollToTop} className="page-footer-span">
                  Nach oben
                </span>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
}
