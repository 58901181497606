import { useState } from 'react';
import '../../css/workSamplesPage.css';
import { ContentIntroSection } from './workSamplesPage/ContentIntroSection';
import { ContentMenuSection } from './workSamplesPage/ContentMenuSection';
import { ContentDesignPatternsSection } from './workSamplesPage/ContentDesignPatternsSection';
import { ContentProjectWorkSection } from './workSamplesPage/ContentProjectWorkSection';
import { ContentReactNativeSection } from './workSamplesPage/ContentReactNativeSection';
import { ContentOtherSection } from './workSamplesPage/ContentOtherSection';

export function WorkSamplesPage(): JSX.Element {
  const [content, setContent] = useState<string>('projectWork');

  function clickMenuNavigation(value: string): void {
    setContent(value);
  }

  return (
    <main className="page-content">
      <ContentIntroSection />
      <ContentMenuSection clickMenu={clickMenuNavigation} content={content} />
      {content === 'projectWork' ? <ContentProjectWorkSection /> : null}
      {content === 'designPatterns' ? <ContentDesignPatternsSection /> : null}
      {content === 'reactNative' ? <ContentReactNativeSection /> : null}
      {content === 'other' ? <ContentOtherSection /> : null}
    </main>
  );
}
