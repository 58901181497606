import '../../../css/contactPage/contactIntroSection.css';

export function ContactIntroSection(): JSX.Element {
  return (
    <div className="intro-content">
      <h4 className="font-color-heading-above">Kontakt</h4>
      <p className="intro-content-1">Fragen? Anregungen?</p>
      <p className="intro-content-2">
        Auf dieser Seite finden Sie Möglichkeiten, mit mir in Kontakt zu treten.
      </p>
    </div>
  );
}
