import '../../css/mePage.css';
import { MeInfoSection } from './mePage/MeInfoSection';
import { MeInfo2Section } from './mePage/MeInfo2Section';
import { MeInfo3Section } from './mePage/MeInfo3Section';

export interface StartPageProps {
  clickMenu: (value: string) => void;
}

export function MePage(props: StartPageProps): JSX.Element {
  function clickMenu(value: string): void {
    props.clickMenu(value);
  }

  return (
    <main className="page-content">
      <MeInfoSection clickMenu={clickMenu} />
      <section className="me-distance wrapper-min-width"></section>
      <MeInfo2Section />
      <section className="me-distance wrapper-min-width"></section>
      <MeInfo3Section />
    </main>
  );
}
