import '../../../css/workSamplesPage/contentReactNativeSection.css';

export function ContentReactNativeSection(): JSX.Element {
  return (
    <section className="workSamples-ReactNative-section">
      <div className="wrapper">
        <div className="workSamples-ReactNative-content">
          <div className="workSamples-ReactNative-content-child-1">
            <h4 className="font-color-heading-above h4-ReactNative-content-child-1">
              React-Native
            </h4>
          </div>
          <div className="workSamples-ReactNative-content-child-2">
            coming soon
          </div>
        </div>
      </div>
    </section>
  );
}
