import { useState } from 'react';
import '../../../../../../css/workSamplesPage/designPatterns/behavioralPatterns/command/command.css';
import pdf from '../../../../../../public/designPatternsPdf/command.pdf';

import { Demo } from './Demo';
import { Diagramm } from './Diagramm';

export function Command(): JSX.Element {
  const [content, setContent] = useState<string>('demo');

  function clickMenu(value: string): void {
    setContent(value);
  }

  return (
    <div>
      <section className="designPatterns-command-section">
        <div className="wrapper">
          <div className="designPatterns-command-content">
            <div className="designPatterns-command-heading-wrapper">
              <div className="designPatterns-command-heading-wrapper-child-1">
                <h4 className="designPatterns-command-heading">
                  Das Command Entwurfsmuster
                </h4>
              </div>
              <div className="designPatterns-command-heading-wrapper-child-2">
                <p
                  onClick={clickMenu.bind('', 'demo')}
                  className="designPatterns-command-heading-1 links-designPatterns"
                >
                  Demo
                </p>
              </div>
              <div className="designPatterns-command-heading-wrapper-child-3">
                <p
                  onClick={clickMenu.bind('', 'diagramm')}
                  className="designPatterns-command-heading-2  links-designPatterns"
                >
                  Diagramm
                </p>
              </div>
              <div className="designPatterns-command-heading-wrapper-child-4">
                <a href={pdf} target="_blank" rel="noreferrer">
                  <i className="icon-designPatterns-file-pdf links-icons-designPatterns"></i>
                </a>
              </div>
            </div>

            <p className="designPatterns-command-content-1">
              In dem Command Pattern kapselt das Kommando-Objekt Befehle. Auf
              diese Weise können Befehle rückgängig gemacht, protokolliert oder
              in einer Warteschlange gelegt werden.
            </p>
          </div>
        </div>
      </section>
      {content === 'demo' ? <Demo /> : null}
      {content === 'diagramm' ? <Diagramm /> : null}
    </div>
  );
}
