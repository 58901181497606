import '../../../css/workSamplesPage/contentIntroSection.css';

export function ContentIntroSection(): JSX.Element {
  return (
    <section className="workSamples-intro-section">
      <div className="wrapper">
        <div className="workSamples-intro-content">
          <h4 className="font-color-heading-above">Arbeitsproben</h4>
          <p className="workSamples-intro-content-1">
            Auf dieser Seite finden Sie einige Arbeitsproben von mir.
          </p>
        </div>
      </div>
    </section>
  );
}
