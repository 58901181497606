import '../../../../../../css/workSamplesPage/designPatterns/structuralPatterns/decorator/demo.css';

export function Demo(): JSX.Element {
  return (
    <section className="designPatterns-decorator-demo-section">
      <div className="wrapper">
        <div className="designPatterns-decorator-demo-content">
          decorator demo
        </div>
      </div>
    </section>
  );
}
