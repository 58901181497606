import { useState } from 'react';
import '../../../../../../css/workSamplesPage/designPatterns/creationalPatterns/singleton/singleton.css';
import pdf from '../../../../../../public/designPatternsPdf/singleton.pdf';

import { Demo } from './Demo';
import { Diagramm } from './Diagramm';

export function Singleton(): JSX.Element {
  const [content, setContent] = useState<string>('demo');

  function clickMenu(value: string): void {
    setContent(value);
  }

  return (
    <div>
      <section className="designPatterns-singleton-section">
        <div className="wrapper">
          <div className="designPatterns-singleton-content">
            <div className="designPatterns-singleton-heading-wrapper">
              <div className="designPatterns-singleton-heading-wrapper-child-1">
                <h4 className="designPatterns-singleton-heading">
                  Das Singleton Entwurfsmuster
                </h4>
              </div>
              <div className="designPatterns-singleton-heading-wrapper-child-2">
                <p
                  onClick={clickMenu.bind('', 'demo')}
                  className="designPatterns-singleton-heading-1 links-designPatterns"
                >
                  Demo
                </p>
              </div>
              <div className="designPatterns-singleton-heading-wrapper-child-3">
                <p
                  onClick={clickMenu.bind('', 'diagramm')}
                  className="designPatterns-singleton-heading-2  links-designPatterns"
                >
                  Diagramm
                </p>
              </div>
              <div className="designPatterns-singleton-heading-wrapper-child-4">
                <a href={pdf} target="_blank" rel="noreferrer">
                  <i className="icon-designPatterns-file-pdf links-icons-designPatterns"></i>
                </a>
              </div>
            </div>

            <p className="designPatterns-singleton-content-1">
              Das Singleton Pattern ist ein Software-Entwurfsmuster, das die
              Instanziierung einer Klasse auf eine "einzelne" Instanz
              beschränkt. Es stellt sicher, dass von einer Klasse genau ein
              Objekt existiert und ist nützlich, wenn genau ein Objekt benötigt
              wird, um Aktionen im gesamten System zu koordinieren.
            </p>
          </div>
        </div>
      </section>
      {content === 'demo' ? <Demo /> : null}
      {content === 'diagramm' ? <Diagramm /> : null}
    </div>
  );
}
