import { useEffect, useState } from 'react';
import '../../../css/startPage/usefulInformationSection.css';
import {
  ApiReturn,
  ApiUsefulInformation,
  UsefulInformation
} from '../../../interfaces/APIDataModel';
import { RestApiUsefulInformation } from '../../../data/RestApiUsefulInformation';

import loadGif from '../../../public/load.gif';

export function UsefulInformationSection(): JSX.Element {
  const [startloadGif, setloadGif] = useState<JSX.Element | null>(
    <div className="loadGif-logo-div">
      <img className="loadGif-logo" src={loadGif} alt="loadGif"></img>
    </div>
  );

  const [dataEvents, setDataEvents] = useState<Array<UsefulInformation> | null>(
    null
  );

  useEffect(() => {
    const fetchDataEvents: () => Promise<void> = async (): Promise<void> => {
      const resultForm: ApiReturn<ApiUsefulInformation> =
        await RestApiUsefulInformation.readForm();
      if (resultForm.content) {
        if (resultForm.content.state) {
          setloadGif(null);
          setDataEvents(resultForm.content.value);
        }
      }
    };
    void fetchDataEvents();
  }, []);

  return (
    <section className="useful-information-section">
      <div className="wrapper">
        <h4 className="font-color-heading-above">Wissenswertes</h4>
        {startloadGif}
        {dataEvents
          ? dataEvents.map((value, index) => (
              <div key={index}>
                <blockquote className="useful-information">
                  <h5 className="useful-information-heading">{value.uber}</h5>
                  <p className="useful-information-content">{value.text}</p>
                </blockquote>
              </div>
            ))
          : ''}
      </div>
    </section>
  );
}
