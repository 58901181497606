import '../../css/contactPage.css';
import { ContactFormSection } from './contactPage/ContactFormSection';
import { ContactIntroSection } from './contactPage/ContactIntroSection';
import { ContactDataSection } from './contactPage/ContactDataSection';
import { ContactMapSection } from './contactPage/ContactMapSection';

export function ContactPage(): JSX.Element {
  return (
    <main className="page-content">
      <section className="contact-section">
        <div className="wrapper">
          <div className="contact">
            <section className="contact-intro">
              <ContactIntroSection />
            </section>
            <section className="contact-data">
              <ContactDataSection />
              <ContactMapSection />
            </section>
            <section className="contact-form">
              <ContactFormSection />
            </section>
          </div>
        </div>
      </section>
    </main>
  );
}
