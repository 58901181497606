import '../../../css/contactPage/contactDataSection.css';

export function ContactDataSection(): JSX.Element {
  return (
    <div className="contact-data-content">
      <h2 className="visually-hidden">Kontaktdaten</h2>
      <address>
        <span className="contact-data-span-heading">
          Kontaktdaten / Impressum
        </span>
        <span className="contact-data-span">Jens Geyer</span>
        <span className="contact-data-span">Klausstraße 17</span>
        <span className="contact-data-span">99310 Arnstadt</span>

        <div className="contact-data-tel">
          <div className="contact-data-tel-child-1">
            <i className="icon-contact-data-whatsapp contact-icon-data-color"></i>
          </div>
          <div className="contact-data-tel-child-2">
            <a href="tel:+49106345789">0174 7061672</a>
          </div>

          <div className="contact-data-tel-child-1">
            <i className="icon-contact-data-tel contact-icon-data-color"></i>
          </div>
          <div className="contact-data-tel-child-2">
            <a href="tel:+49106345789">0174 7061672</a>
          </div>

          <div className="contact-data-tel-child-1">
            <i className="icon-contact-data-mail contact-icon-data-color"></i>
          </div>
          <div className="contact-data-tel-child-2">
            <span className="icon-contact-data-mail-span">
              kontakt@jens-geyer.de
            </span>
          </div>
        </div>
      </address>
    </div>
  );
}
