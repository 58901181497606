import '../../../css/workSamplesPage/contentProjectWorkSection.css';

import kundenmanagement from '../../../public/Projektdokumentation_Kundenmanagement-Tool.pdf';
import statistik from '../../../public/Projektdokumentation_Zeit-Statistik-Tool.pdf';

export function ContentProjectWorkSection(): JSX.Element {
  return (
    <section className="workSamples-projectWork-section">
      <div className="wrapper">
        <div className="workSamples-projectWork-content">
          <div className="workSamples-projectWork-content-child-1">
            <h4 className="font-color-heading-above h4-projectWork-content-child-1">
              Projektarbeit
            </h4>
          </div>
          <div className="workSamples-projectWork-content-child-2"></div>
        </div>
        <div className="workSamples-projectWork-content">
          <div className="workSamples-projectWork-content-child-1">
            <p>Abschlussprüfung Winter 2021</p>Projektarbeit: Entwicklung eines
            Zeit-Statistik-Tool für die Software Versionsverwaltung „GitLab“
            (Nichtteilnahme wegen Corona-Infektion)
          </div>
          <div className="workSamples-projectWork-content-child-2">
            <a
              href={statistik}
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              <i className="icon-designPatterns-file-pdf links-icons-designPatterns"></i>
            </a>
          </div>
        </div>
        <div className="workSamples-projectWork-content">
          <div className="workSamples-projectWork-content-child-1">
            <p>Abschlussprüfung Sommer 2022</p>Projektarbeit: Entwicklung eines
            CRM Kundenmanagement-Tool für Freiberufler und Selbständige (neues
            Pflichtprojekt da das alte Projekt, trotz der Nichtbenutzung nicht
            verwendet werden durfte)
          </div>
          <div className="workSamples-projectWork-content-child-2">
            <a
              href={kundenmanagement}
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              <i className="icon-designPatterns-file-pdf links-icons-designPatterns"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
