import '../../css/mePage.css';
import { MeInfoSection } from './info/InfoSection';

export function Info(): JSX.Element {
  return (
    <main className="page-content">
      <section className="me-distance2 wrapper-min-width"></section>
      <MeInfoSection />
    </main>
  );
}
