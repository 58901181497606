import '../../../../../../css/workSamplesPage/designPatterns/creationalPatterns/builder/diagramm.css';
import allgemein from '../../../../../../public/designPatterns/builder/allgemein.svg';
import demo from '../../../../../../public/designPatterns/builder/demo.svg';

export function Diagramm(): JSX.Element {
  return (
    <section className="designPatterns-builder-diagramm-section">
      <div className="wrapper">
        <div className="designPatterns-builder-diagramm-content">
          <span className="designPatterns-builder-diagramm-slogan">
            UML Diagramm (Builder Pattern allgemein)
          </span>
          <img
            className="designPatterns-builder-diagramm-logo"
            src={allgemein}
            alt="designPatterns-builder-diagramm"
            width="265"
            height="179"
          ></img>
          <span className="designPatterns-builder-diagramm-slogan-2">
            UML Diagramm (Builder Pattern Demo)
          </span>
          <img
            className="designPatterns-builder-diagramm-logo-2"
            src={demo}
            alt="designPatterns-builder-diagramm"
            width="265"
            height="283"
          ></img>
        </div>
      </div>
    </section>
  );
}
