import '../../../css/mePage/meInfoSection.css';

export interface InfoBoxesSectionProps {
  clickMenu: (value: string) => void;
}

export function MeInfoSection(props: InfoBoxesSectionProps): JSX.Element {
  function clickMenu(value: string): void {
    props.clickMenu(value);
  }

  return (
    <section className="me-info-section">
      <div className="wrapper">
        <div className="me-info">
          <div className="me-info-child-1">
            <h4 className="font-color-heading-above">Über mich</h4>
            <p className="me-info-content-1">
              Anwendungsentwicklung ist der gesamte Prozess der Herstellung von
              Software und besteht aus verschiedenen Aufgaben, zu denen neben
              der Programmierung auch die Wartung und Aktualisierung zählen.
            </p>
            <p className="me-info-content-2">
              Auch der Entwurf und die Planung der Software gehört zu den
              Aufgaben des Anwendungsentwicklers.
            </p>
            <p className="me-info-content-3">
              zu meinen spezielen Aufgaben gehören:
            </p>
            <ul>
              <li>Webentwicklung</li>
              <li>Mobile App's</li>
              <li>individuelle Software</li>
              <li>Front-End & Back-End Entwicklung</li>
            </ul>
            <p className="me-info-content-4">
              Hier gibts{' '}
              <span onClick={clickMenu.bind('', 'info')} className="link-after">
                mehr Infos
              </span>{' '}
              zu den Qualitätsmerkmalen der Anwendungsentwicklung.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
