import '../../css/startPage.css';
import { ContentIntroSection } from './startPage/ContentIntroSection';
import { InfoBoxesSection } from './startPage/InfoBoxesSection';
import { UsefulInformationSection } from './startPage/UsefulInformationSection';

export interface StartPageProps {
  clickMenu: (value: string) => void;
}

export function StartPage(props: StartPageProps): JSX.Element {
  function clickMenu(value: string): void {
    props.clickMenu(value);
  }

  return (
    <main className="page-content">
      <ContentIntroSection />
      <InfoBoxesSection clickMenu={clickMenu} />
      <UsefulInformationSection />
    </main>
  );
}
