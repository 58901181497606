import { ChangeEvent, useEffect, useState } from 'react';
// import { ApiContactForm } from '../../interfaces/APIDataModel';
import { ApiReturn, ApiContactForm } from '../../../interfaces/APIDataModel';
import { RestApiMailer } from '../../../data/RestApiMailer';
import '../../../css/contactPage/contactFormSection.css';
import loadGif from '../../../public/load.gif';

export interface ErrorText {
  textName: string;
  textMail: string;
  textMessage: string;
}

export interface SendFormContent {
  name: string;
  mail: string;
  message: string;
  state: boolean;
}

export function ContactFormSection(): JSX.Element {
  const [contactName, setContactName] = useState<string>(
    'Bitte Ihren Name eintragen'
  );
  const [contactEmail, setContactEmail] = useState<string>(
    'Bitte Ihre E-Mail eintragen'
  );
  const [contactMessage, setContactMessage] = useState<string>(
    'Hier können Sie Ihre Nachricht an mich eintragen...'
  );

  const [posted, setPosted] = useState<string>(
    'contactForm-error-text-display-none'
  );

  const [sendPosted, setSendPosted] = useState<string>(
    'contactForm-error-text-display-none'
  );

  const [notPosted, setNotPosted] = useState<string>(
    'contactForm-error-text-display-none'
  );

  const [errorText, setErrorText] = useState<ErrorText>({
    textName: 'contactForm-error-text-display-none',
    textMail: 'contactForm-error-text-display-none',
    textMessage: 'contactForm-error-text-display-none'
  });

  const [sendForm, setSendForm] = useState<SendFormContent>({
    name: '',
    mail: '',
    message: '',
    state: false
  });

  useEffect(() => {
    const fetchClick: () => Promise<void> = async (): Promise<void> => {
      const resultForm: ApiReturn<ApiContactForm> =
        await RestApiMailer.readForm(
          sendForm.name,
          sendForm.mail,
          sendForm.message
        );

      if (resultForm.content) {
        if (resultForm.content.state.all === true) {
          setSendPosted('contactForm-error-text-display-none');
          setPosted('contact-form-div');
          setContactEmail('');
          setContactName('');
          setContactMessage('');
        } else {
          setSendPosted('contactForm-error-text-display-none');
          setErrorText({
            textName:
              resultForm.content.state.name === false
                ? 'contact-form-div'
                : 'contactForm-error-text-display-none',
            textMail:
              resultForm.content.state.mail === false
                ? 'contact-form-div'
                : 'contactForm-error-text-display-none',
            textMessage:
              resultForm.content.state.message === false
                ? 'contact-form-div'
                : 'contactForm-error-text-display-none'
          });
        }
      } else {
        setSendPosted('contactForm-error-text-display-none');
        setNotPosted('contact-form-div');
      }
    };
    if (sendForm.state) {
      setSendPosted('contact-form-div');
      void fetchClick();
    }
  }, [sendForm]);

  function changeContactFormName(
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ): void {
    setContactName(e.target.value);

    if (checkName(e.target.value)) {
      const contactTextName: string = 'contact-form-div';
      const contactTextMail: string = errorText.textMail;
      const contactTextMessage: string = errorText.textMessage;

      setErrorText({
        textName: contactTextName,
        textMail: contactTextMail,
        textMessage: contactTextMessage
      });
    } else {
      const contactTextName: string = 'contactForm-error-text-display-none';
      const contactTextMail: string = errorText.textMail;
      const contactTextMessage: string = errorText.textMessage;

      setErrorText({
        textName: contactTextName,
        textMail: contactTextMail,
        textMessage: contactTextMessage
      });
    }
  }

  function changeContactFormMail(
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ): void {
    setContactEmail(e.target.value);

    if (checkMail(e.target.value)) {
      const contactTextName: string = errorText.textName;
      const contactTextMail: string = 'contact-form-div';
      const contactTextMessage: string = errorText.textMessage;

      setErrorText({
        textName: contactTextName,
        textMail: contactTextMail,
        textMessage: contactTextMessage
      });
    } else {
      const contactTextName: string = errorText.textName;
      const contactTextMail: string = 'contactForm-error-text-display-none';
      const contactTextMessage: string = errorText.textMessage;

      setErrorText({
        textName: contactTextName,
        textMail: contactTextMail,
        textMessage: contactTextMessage
      });
    }
  }

  function changeContactFormMessage(
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ): void {
    setContactMessage(e.target.value);

    if (checkMessage(e.target.value)) {
      const contactTextName: string = errorText.textName;
      const contactTextMail: string = errorText.textMail;
      const contactTextMessage: string = 'contact-form-div';

      setErrorText({
        textName: contactTextName,
        textMail: contactTextMail,
        textMessage: contactTextMessage
      });
    } else {
      const contactTextName: string = errorText.textName;
      const contactTextMail: string = errorText.textMail;
      const contactTextMessage: string = 'contactForm-error-text-display-none';

      setErrorText({
        textName: contactTextName,
        textMail: contactTextMail,
        textMessage: contactTextMessage
      });
    }
  }

  function onClickForm(): void {
    setNotPosted('contactForm-error-text-display-none');
    setPosted('contactForm-error-text-display-none');
    setSendPosted('contactForm-error-text-display-none');

    let contactTextName: string = 'contactForm-error-text-display-none';
    let contactTextMail: string = 'contactForm-error-text-display-none';
    let contactTextMessage: string = 'contactForm-error-text-display-none';
    let formSendState: boolean = true;

    if (checkName(contactName)) {
      contactTextName = 'contact-form-div';
      formSendState = false;
    }

    if (checkMail(contactEmail)) {
      contactTextMail = 'contact-form-div';
      formSendState = false;
    }

    if (checkMessage(contactMessage)) {
      contactTextMessage = 'contact-form-div';
      formSendState = false;
    }

    if (formSendState) {
      setSendForm({
        name: contactName,
        mail: contactEmail,
        message: contactMessage,
        state: true
      });
    }

    setErrorText({
      textName: contactTextName,
      textMail: contactTextMail,
      textMessage: contactTextMessage
    });
  }

  function checkName(name: string): boolean {
    return !name.match(/^[a-zA-Z0-9äöüÄÖÜß ]{1,100}$/);
  }
  function checkMessage(message: string): boolean {
    return !message.match(
      // eslint-disable-next-line no-useless-escape
      /^[a-zA-Z0-9äöüÄÖÜß\\\+\*\?\[\]\$\(\)\{\}\=\!\<\>\|\:\-\#\. „“§%_,;`´\'\"\&\/\@\n]{1,500}$/
    );
  }
  function checkMail(mail: string): boolean {
    return !mail.match(
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }
  function postedClose(): void {
    setPosted('contactForm-error-text-display-none');
  }
  function notPostedClose(): void {
    setNotPosted('contactForm-error-text-display-none');
  }
  return (
    <div className="contact-form-wrapper">
      <h2 className="visually-hidden">Kontaktformular</h2>
      <p className="contact-form-p">
        Mit dem folgenden Formular können Sie mir eine Nachricht schicken.
      </p>
      <div className="contactForm">
        <div className={sendPosted}>
          <div className="contactForm-not-posted-img">
            <img
              className="contact-form-img"
              src={loadGif}
              srcSet={loadGif + ' 2x, ' + loadGif + ' 3x'}
              alt=""
            ></img>
          </div>
        </div>

        <div className={notPosted}>
          <div className="contactForm-not-posted-text">
            <div className="contactForm-not-posted-text-1">
              <i className="icon-contact-data-info contact-form-icon-data-color"></i>
              Ihre Nachricht konnte nicht an mich gesendet werden.
            </div>
            <div className="contactForm-not-posted-text-2">
              <span onClick={notPostedClose}>schließen</span>
            </div>
          </div>
        </div>

        <div className={posted}>
          <div className="contactForm-posted-text">
            <div className="contactForm-posted-text-1">
              <i className="icon-contact-data-info contact-form-icon-data-color"></i>
              Ihre Nachricht wurde erfolgreich an mich gesendet.
            </div>
            <div className="contactForm-posted-text-2">
              <span onClick={postedClose}>schließen</span>
            </div>
          </div>
        </div>
        <div className="contact-form-div">
          <label className="contact-form-label" htmlFor="name">
            <span className="contact-form-span">Ihr Name:</span>
          </label>
          <input
            className="contactForm-input"
            value={contactName}
            type="name"
            id="name"
            name="name"
            onChange={changeContactFormName}
          />
        </div>
        <div className={errorText.textName}>
          <div className="contactForm-error-text">
            <i className="icon-contact-data-info contact-form-icon-data-color"></i>
            der Name muss angegeben sein (nur Buchstaben und Zahlen, max 100
            Zeichen)
          </div>
        </div>
        <div className="contact-form-div">
          <label className="contact-form-label" htmlFor="email">
            <span className="contact-form-span">Ihre E-Mail:</span>
          </label>
          <input
            className="contactForm-input"
            value={contactEmail}
            type="email"
            id="email"
            name="email"
            onChange={changeContactFormMail}
          />
        </div>
        <div className={errorText.textMail}>
          <div className="contactForm-error-text">
            <i className="icon-contact-data-info contact-form-icon-data-color"></i>
            die E-Mail muss angegeben und eine gültige Adresse sein
          </div>
        </div>
        <div className="contact-form-div">
          <label className="contact-form-label" htmlFor="nachricht">
            <span className="contact-form-span">Ihre Nachricht:</span>
          </label>
          <textarea
            className="contactForm-textarea"
            id="nachricht"
            name="nachricht"
            cols={20}
            rows={5}
            value={contactMessage}
            onChange={changeContactFormMessage}
          />
        </div>
        <div className={errorText.textMessage}>
          <div className="contactForm-error-text">
            <i className="icon-contact-data-info contact-form-icon-data-color"></i>
            die Nachricht muss angegeben sein (nur Buchstaben, Zahlen und
            Sonderzeichen, max 500 Zeichen)
          </div>
        </div>
        <div className="contact-form-div">
          <button className="contactForm-button" onClick={onClickForm}>
            Abschicken
          </button>
        </div>
      </div>
    </div>
  );
}
