import '../../../../../../css/workSamplesPage/designPatterns/creationalPatterns/singleton/demo.css';

class Singleton {
  private static instance = new Singleton();
  private testNumber: number | null = null;
  private testName: string | null = null;

  private constructor() {
    this.testNumber = 22;
    this.testName = 'Test Name';
  }

  public static getInstance(): Singleton {
    return this.instance;
  }

  public getName(): string | null {
    return this.testName;
  }

  public getNumber(): number | null {
    return this.testNumber;
  }
}

export function Demo(): JSX.Element {
  const singletonOne: Singleton = Singleton.getInstance();
  const singletonTwo: Singleton = Singleton.getInstance();
  const singletonThree: Singleton = Singleton.getInstance();

  return (
    <section className="designPatterns-singleton-demo-section">
      <div className="wrapper">
        <div className="designPatterns-singleton-demo-content">
          <span className="designPatterns-singleton-demo-slogan">
            Da es relativ schwierig ist, ein Singleton Pattern in einem Demo zu
            testen habe ich eine einfache und effiziente Zurverfügungstellung
            von Nur-Lese-Daten erstellt. Diese könnten z.B. aus einer Datenbank
            beim Start der Anwendung geladen werden.
          </span>
          <span className="designPatterns-singleton-demo-slogan">
            In der Klasse Singleton werden ein Test-Name und eine Test-Nummer
            zur Verfügung gestellt.
          </span>
          <span className="designPatterns-singleton-demo-slogan">
            <span className="designPatterns-singleton-demo-slogan_2">
              SingletonOne:
            </span>
            <span className="designPatterns-singleton-demo-slogan_3">
              Name: {singletonOne.getName()}
            </span>
            <span className="designPatterns-singleton-demo-slogan_3">
              Nummer: {singletonOne.getNumber()}
            </span>
          </span>
          <span className="designPatterns-singleton-demo-slogan">
            <span className="designPatterns-singleton-demo-slogan_2">
              SingletonTwo:
            </span>
            <span className="designPatterns-singleton-demo-slogan_3">
              Name: {singletonTwo.getName()}
            </span>
            <span className="designPatterns-singleton-demo-slogan_3">
              Nummer: {singletonTwo.getNumber()}
            </span>
          </span>
          <span className="designPatterns-singleton-demo-slogan">
            <span className="designPatterns-singleton-demo-slogan_2">
              SingletonThree:
            </span>
            <span className="designPatterns-singleton-demo-slogan_3">
              Name: {singletonThree.getName()}
            </span>
            <span className="designPatterns-singleton-demo-slogan_3">
              Nummer: {singletonThree.getNumber()}
            </span>
          </span>
        </div>
      </div>
    </section>
  );
}
