import { useState } from 'react';
import '../../../css/workSamplesPage/contentDesignPatternsSection.css';
import { DesignPatternsMenu } from './designPatterns/DesignPatternsMenu';

// import Design Patterns
import { Observer } from './designPatterns/behavioralPatterns/observer/Observer';
import { Command } from './designPatterns/behavioralPatterns/command/Command';
import { State } from './designPatterns/behavioralPatterns/state/State';
import { Strategy } from './designPatterns/behavioralPatterns/strategy/Strategy';
import { Visitor } from './designPatterns/behavioralPatterns/visitor/Visitor';
import { AbstractFactory } from './designPatterns/creationalPatterns/abstractFactory/AbstractFactory';
import { Builder } from './designPatterns/creationalPatterns/builder/Builder';
import { FactoryMethod } from './designPatterns/creationalPatterns/factoryMethod/FactoryMethod';
import { Singleton } from './designPatterns/creationalPatterns/singleton/Singleton';
import { Composite } from './designPatterns/structuralPatterns/composite/Composite';
import { Decorator } from './designPatterns/structuralPatterns/decorator/Decorator';
import { Facade } from './designPatterns/structuralPatterns/facade/Facade';

export function ContentDesignPatternsSection(): JSX.Element {
  const [content, setContent] = useState<string>('observer');

  function clickMenuNavigation(value: string): void {
    setContent(value);
  }

  return (
    <div>
      <section className="workSamples-designPatterns-section">
        <div className="wrapper">
          <div className="workSamples-designPatterns-content">
            <div className="workSamples-designPatterns-content-child-1">
              <h4 className="font-color-heading-above h4-designPatterns-content-child-1">
                Design Patterns
              </h4>
            </div>
            <div className="workSamples-designPatterns-content-child-2">
              <DesignPatternsMenu
                clickMenu={clickMenuNavigation}
                content={content}
              />
            </div>
          </div>
        </div>
      </section>
      {content === 'command' ? <Command /> : null}
      {content === 'observer' ? <Observer /> : null}
      {content === 'state' ? <State /> : null}
      {content === 'strategy' ? <Strategy /> : null}
      {content === 'visitor' ? <Visitor /> : null}
      {content === 'abstractFactory' ? <AbstractFactory /> : null}
      {content === 'builder' ? <Builder /> : null}
      {content === 'factoryMethod' ? <FactoryMethod /> : null}
      {content === 'singleton' ? <Singleton /> : null}
      {content === 'composite' ? <Composite /> : null}
      {content === 'decorator' ? <Decorator /> : null}
      {content === 'facade' ? <Facade /> : null}
    </div>
  );
}
