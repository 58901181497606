import '../../../../../../css/workSamplesPage/designPatterns/behavioralPatterns/visitor/demo.css';

export function Demo(): JSX.Element {
  return (
    <section className="designPatterns-visitor-demo-section">
      <div className="wrapper">
        <div className="designPatterns-visitor-demo-content">visitor demo</div>
      </div>
    </section>
  );
}
