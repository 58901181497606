import '../../../css/mePage/meInfo2Section.css';
import mePage_1 from '../../../public/mePage_1.jpg';
import mePage_2 from '../../../public/mePage_2.jpg';
import mePage_3 from '../../../public/mePage_3.jpg';

export function MeInfo2Section(): JSX.Element {
  return (
    <section className="me-info2-section">
      <div className="wrapper">
        <div className="me-info2">
          <div className="me-info2-child">
            <h5 className="me-info2-heading">Erfahrungen</h5>
            <p className="me-info2-content">
              <div className="me-info2-content-ul">
                <div className="me-info2-content-ul-child-1">
                  <i className="icon-me-info2-content-li"></i>
                </div>
                <div className="me-info2-content-ul-child-2">
                  <div className="me-info2-content-li-span">
                    gute Kentnisse: HTML, CSS, JavaScript, TypeScript, PHP, SQL,
                    React, React-Native
                  </div>
                </div>
                <div className="me-info2-content-ul-child-1">
                  <i className="icon-me-info2-content-li"></i>
                </div>
                <div className="me-info2-content-ul-child-2">
                  <div className="me-info2-content-li-span">
                    Kentnisse: Pyton
                  </div>
                </div>
                <div className="me-info2-content-ul-child-1">
                  <i className="icon-me-info2-content-li"></i>
                </div>
                <div className="me-info2-content-ul-child-2">
                  <div className="me-info2-content-li-span">
                    Grundlagen: C#, Java
                  </div>
                </div>
              </div>
            </p>
          </div>
          <div className="me-info2-child">
            <img
              className="me-img"
              src={mePage_1}
              srcSet={mePage_1 + ' 2x, ' + mePage_1 + ' 3x'}
              alt=""
            ></img>
          </div>
          <div className="me-info2-child">
            <h5 className="me-info2-heading">Arbeitsumgebung</h5>
            <p className="me-info2-content">
              <div className="me-info2-content-ul">
                <div className="me-info2-content-ul-child-1">
                  <i className="icon-me-info2-content-li"></i>
                </div>
                <div className="me-info2-content-ul-child-2">
                  <div className="me-info2-content-li-span">
                    Windows 10, Git, npm
                  </div>
                </div>
                <div className="me-info2-content-ul-child-1">
                  <i className="icon-me-info2-content-li"></i>
                </div>
                <div className="me-info2-content-ul-child-2">
                  <div className="me-info2-content-li-span">
                    Visual Studio 2019, Visual Studio Code
                  </div>
                </div>
                <div className="me-info2-content-ul-child-1">
                  <i className="icon-me-info2-content-li"></i>
                </div>
                <div className="me-info2-content-ul-child-2">
                  <div className="me-info2-content-li-span">
                    XAMPP, MySQL Workbench, SQL Server Management Studio
                  </div>
                </div>
                <div className="me-info2-content-ul-child-1">
                  <i className="icon-me-info2-content-li"></i>
                </div>
                <div className="me-info2-content-ul-child-2">
                  <div className="me-info2-content-li-span">
                    Eclipse, Android-Studio
                  </div>
                </div>
              </div>
            </p>
          </div>
          <div className="me-info2-child">
            <img
              className="me-img"
              src={mePage_2}
              srcSet={mePage_2 + ' 2x, ' + mePage_2 + ' 3x'}
              alt=""
            ></img>
          </div>
          <div className="me-info2-child">
            <h5 className="me-info2-heading">Ausbildung</h5>
            <p className="me-info2-content">
              <div className="me-info2-content-ul">
                <div className="me-info2-content-ul-child-1">
                  <i className="icon-me-info2-content-li"></i>
                </div>
                <div className="me-info2-content-ul-child-2">
                  <div className="me-info2-content-li-span">
                    Umschulung zum Fachinformatiker für Anwendungsentwicklung
                    (2019 - 2022)
                  </div>
                </div>

                <div className="me-info2-content-ul-child-1">
                  <i className="icon-me-info2-content-li"></i>
                </div>
                <div className="me-info2-content-ul-child-2">
                  <div className="me-info2-content-li-span">
                    bestandene Abschlussprüfung - IHK Erfurt (Juni 2022)
                  </div>
                </div>
              </div>
            </p>
          </div>
          <div className="me-info2-child">
            <img
              className="me-img"
              src={mePage_3}
              srcSet={mePage_3 + ' 2x, ' + mePage_3 + ' 3x'}
              alt=""
            ></img>
          </div>
        </div>
      </div>
    </section>
  );
}
