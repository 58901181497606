import '../../../css/mePage/meInfo3Section.css';

export function MeInfo3Section(): JSX.Element {
  return (
    <section className="me-info3-section">
      <div className="wrapper">
        <h4 className="font-color-heading-above">
          Tätigkeiten und Erfahrungen im IT-Bereich
        </h4>

        <div className="me-info3-content-ul">
          <div className="me-info3-content-ul-child-1">
            <i className="icon-me-info3-content-li"></i>
          </div>
          <div className="me-info3-content-ul-child-2">
            <div className="me-info3-content-li-span">
              2000 - 2006: Nebenberuflich Selbstständig (Webentwicklung)
            </div>
          </div>
          <div className="me-info3-content-ul-child-1">
            <i className="icon-me-info3-content-li"></i>
          </div>
          <div className="me-info3-content-ul-child-2">
            <div className="me-info3-content-li-span">
              2019 - 2022: Umschulung zum Fachinformatiker für
              Anwendungsentwicklung
            </div>
          </div>
          <div className="me-info3-content-ul-child-1">
            <i className="icon-me-info3-content-li"></i>
          </div>
          <div className="me-info3-content-ul-child-2">
            <div className="me-info3-content-li-span">
              2021 - 2021: verschiedene Projekte mit React und React-Native
            </div>
          </div>

          <div className="me-info3-content-ul-child-1">
            <i className="icon-me-info3-content-li"></i>
          </div>
          <div className="me-info3-content-ul-child-2">
            <div className="me-info3-content-li-span">
              2022 - 2022: verschiedene Projekte mit PHP und SQL
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
