import '../../../../../../css/workSamplesPage/designPatterns/behavioralPatterns/visitor/diagramm.css';
import allgemein from '../../../../../../public/designPatterns/visitor/allgemein.svg';
import demo from '../../../../../../public/designPatterns/visitor/demo.svg';

export function Diagramm(): JSX.Element {
  return (
    <section className="designPatterns-visitor-diagramm-section">
      <div className="wrapper">
        <div className="designPatterns-visitor-diagramm-content">
          <span className="designPatterns-visitor-diagramm-slogan">
            UML Diagramm (Visitor Pattern allgemein)
          </span>
          <img
            className="designPatterns-visitor-diagramm-logo"
            src={allgemein}
            alt="designPatterns-visitor-diagramm"
            width="265"
            height="179"
          ></img>
          <span className="designPatterns-visitor-diagramm-slogan-2">
            UML Diagramm (Visitor Pattern Demo)
          </span>
          <img
            className="designPatterns-visitor-diagramm-logo-2"
            src={demo}
            alt="designPatterns-visitor-diagramm"
            width="265"
            height="283"
          ></img>
        </div>
      </div>
    </section>
  );
}
