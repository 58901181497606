import { useState } from 'react';
import '../../../../../../css/workSamplesPage/designPatterns/creationalPatterns/abstractFactory/abstractFactory.css';
import pdf from '../../../../../../public/designPatternsPdf/abstractFactory.pdf';

import { Demo } from './Demo';
import { Diagramm } from './Diagramm';

export function AbstractFactory(): JSX.Element {
  const [content, setContent] = useState<string>('demo');

  function clickMenu(value: string): void {
    setContent(value);
  }

  return (
    <div>
      <section className="designPatterns-abstractFactory-section">
        <div className="wrapper">
          <div className="designPatterns-abstractFactory-content">
            <div className="designPatterns-abstractFactory-heading-wrapper">
              <div className="designPatterns-abstractFactory-heading-wrapper-child-1">
                <h4 className="designPatterns-abstractFactory-heading">
                  Das Abstract Factory Entwurfsmuster
                </h4>
              </div>
              <div className="designPatterns-abstractFactory-heading-wrapper-child-2">
                <p
                  onClick={clickMenu.bind('', 'demo')}
                  className="designPatterns-abstractFactory-heading-1 links-designPatterns"
                >
                  Demo
                </p>
              </div>
              <div className="designPatterns-abstractFactory-heading-wrapper-child-3">
                <p
                  onClick={clickMenu.bind('', 'diagramm')}
                  className="designPatterns-abstractFactory-heading-2  links-designPatterns"
                >
                  Diagramm
                </p>
              </div>
              <div className="designPatterns-abstractFactory-heading-wrapper-child-4">
                <a href={pdf} target="_blank" rel="noreferrer">
                  <i className="icon-designPatterns-file-pdf links-icons-designPatterns"></i>
                </a>
              </div>
            </div>

            <p className="designPatterns-abstractFactory-content-1">
              Das Observer Pattern ist ein Software-Entwurfsmuster, bei dem ein
              Objekt (Observer, beobachtendes Objekt), eine Liste seiner
              abhängigen Objekte (Subject, beobachtetes Objekt), verwaltet und
              sie automatisch über alle Zustandsänderungen benachrichtigt.
            </p>
          </div>
        </div>
      </section>
      {content === 'demo' ? <Demo /> : null}
      {content === 'diagramm' ? <Diagramm /> : null}
    </div>
  );
}
