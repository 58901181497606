import { useState } from 'react';
import '../../../css/workSamplesPage/contentMenuSection.css';

interface NavigationComponents {
  projectWork: boolean;
  designPatterns: boolean;
  reactNative: boolean;
  other: boolean;
}

export interface NavigationProps {
  clickMenu: (value: string) => void;
  content: string;
}

export function ContentMenuSection(props: NavigationProps): JSX.Element {
  const [menuClassState, setClassState] = useState<string>(
    'workSamples-menubutton'
  );
  const [menuPlaceState, setMenuPlaceState] =
    useState<string>('designPatterns');
  const [menuComponent, setMenuComponent] = useState<NavigationComponents>({
    projectWork: false,
    designPatterns: false,
    reactNative: false,
    other: false
  });

  function menu(): void {
    if (menuClassState === 'workSamples-menubutton') {
      setClassState('workSamples-menubutton workSamples-show-menu');
    } else {
      setClassState('workSamples-menubutton');
    }
  }

  function clickMenu(value: string): void {
    setMenuPlaceState(value);
    if (menuClassState !== 'workSamples-menubutton') {
      setClassState('workSamples-menubutton');
    }
    props.clickMenu(value);
  }

  function mouseOverMenu(value: Array<boolean>): void {
    const component: NavigationComponents = {
      projectWork: value[0],
      designPatterns: value[1],
      reactNative: value[2],
      other: value[3]
    };
    setMenuComponent(component);
  }

  function mouseOutMenu(): void {
    const component: NavigationComponents = {
      projectWork: false,
      designPatterns: false,
      reactNative: false,
      other: false
    };
    setMenuComponent(component);
  }

  if (menuPlaceState !== props.content) {
    setMenuPlaceState(props.content);
  }

  return (
    <nav className="workSamples-navigation wrapper-min-width">
      <div className="wrapper">
        <button onClick={menu} className={menuClassState}>
          <i className="icon-menu"></i>&nbsp;&nbsp;Arbeitsproben-Menü
        </button>
        <ul className="workSamples-navigation-list">
          <li>
            <span
              onClick={clickMenu.bind('', 'projectWork')}
              onMouseOver={mouseOverMenu.bind('', [true, false, false, false])}
              onMouseOut={mouseOutMenu}
              className={
                menuPlaceState === 'projectWork'
                  ? 'workSamples-you-are-here'
                  : ''
              }
            >
              {menuComponent.projectWork ? 'Projektarbeit' : 'Projektarbeit'}
            </span>
          </li>
          <li>
            <span
              onClick={clickMenu.bind('', 'designPatterns')}
              onMouseOver={mouseOverMenu.bind('', [false, true, false, false])}
              onMouseOut={mouseOutMenu}
              className={
                menuPlaceState === 'designPatterns'
                  ? 'workSamples-you-are-here'
                  : ''
              }
            >
              {menuComponent.designPatterns
                ? 'Entwurfsmuster'
                : 'Entwurfsmuster'}
            </span>
          </li>
          <li>
            <span
              onClick={clickMenu.bind('', 'reactNative')}
              onMouseOver={mouseOverMenu.bind('', [false, false, true, false])}
              onMouseOut={mouseOutMenu}
              className={
                menuPlaceState === 'reactNative'
                  ? 'workSamples-you-are-here'
                  : ''
              }
            >
              {menuComponent.reactNative ? 'React-Native' : 'React-Native'}
            </span>
          </li>
          <li>
            <span
              onClick={clickMenu.bind('', 'other')}
              onMouseOver={mouseOverMenu.bind('', [false, false, false, true])}
              onMouseOut={mouseOutMenu}
              className={
                menuPlaceState === 'other' ? 'workSamples-you-are-here' : ''
              }
            >
              {menuComponent.other ? 'Sonstiges' : 'Sonstiges'}
            </span>
          </li>
        </ul>
      </div>
    </nav>
  );
}
