import { useState } from 'react';
import '../css/navigation.css';

interface NavigationComponents {
  startPage: boolean;
  mePage: boolean;
  contactPage: boolean;
  workSamplesPage: boolean;
}

export interface NavigationProps {
  clickMenu: (value: string) => void;
  content: string;
}

export function Navigation(props: NavigationProps): JSX.Element {
  const [menuClassState, setClassState] = useState<string>('menubutton');
  const [menuPlaceState, setMenuPlaceState] = useState<string>('startPage');
  const [menuComponent, setMenuComponent] = useState<NavigationComponents>({
    startPage: false,
    mePage: false,
    contactPage: false,
    workSamplesPage: false
  });

  function menu(): void {
    if (menuClassState === 'menubutton') {
      setClassState('menubutton show-menu');
    } else {
      setClassState('menubutton');
    }
  }

  function clickMenu(value: string): void {
    setMenuPlaceState(value);
    if (menuClassState !== 'menubutton') {
      setClassState('menubutton');
    }
    props.clickMenu(value);
  }

  function mouseOverMenu(value: Array<boolean>): void {
    const component: NavigationComponents = {
      startPage: value[0],
      mePage: value[1],
      contactPage: value[2],
      workSamplesPage: value[3]
    };
    setMenuComponent(component);
  }

  function mouseOutMenu(): void {
    const component: NavigationComponents = {
      startPage: false,
      mePage: false,
      contactPage: false,
      workSamplesPage: false
    };
    setMenuComponent(component);
  }

  if (menuPlaceState !== props.content) {
    setMenuPlaceState(props.content);
  }

  return (
    <nav className="page-navigation wrapper-min-width">
      <div className="wrapper">
        <button onClick={menu} className={menuClassState}>
          <i className="icon-menu"></i>&nbsp;&nbsp;Menü
        </button>
        <ul className="page-navigation-list">
          <li>
            <span
              onClick={clickMenu.bind('', 'startPage')}
              onMouseOver={mouseOverMenu.bind('', [true, false, false, false])}
              onMouseOut={mouseOutMenu}
              className={menuPlaceState === 'startPage' ? 'you-are-here' : ''}
            >
              {menuComponent.startPage ? '> Startseite' : 'Startseite'}
            </span>
          </li>
          <li>
            <span
              onClick={clickMenu.bind('', 'mePage')}
              onMouseOver={mouseOverMenu.bind('', [false, true, false, false])}
              onMouseOut={mouseOutMenu}
              className={menuPlaceState === 'mePage' ? 'you-are-here' : ''}
            >
              {menuComponent.mePage ? '> Über mich' : 'Über mich'}
            </span>
          </li>
          <li>
            <span
              onClick={clickMenu.bind('', 'contactPage')}
              onMouseOver={mouseOverMenu.bind('', [false, false, true, false])}
              onMouseOut={mouseOutMenu}
              className={menuPlaceState === 'contactPage' ? 'you-are-here' : ''}
            >
              {menuComponent.contactPage ? '> Kontakt' : 'Kontakt'}
            </span>
          </li>
          <li>
            <span
              onClick={clickMenu.bind('', 'workSamplesPage')}
              onMouseOver={mouseOverMenu.bind('', [false, false, false, true])}
              onMouseOut={mouseOutMenu}
              className={
                menuPlaceState === 'workSamplesPage' ? 'you-are-here' : ''
              }
            >
              {menuComponent.workSamplesPage
                ? '> Arbeitsproben'
                : 'Arbeitsproben'}
            </span>
          </li>
        </ul>
      </div>
    </nav>
  );
}
