import { useState } from 'react';
import '../../../../../../css/workSamplesPage/designPatterns/structuralPatterns/facade/facade.css';
import pdf from '../../../../../../public/designPatternsPdf/facade.pdf';

import { Demo } from './Demo';
import { Diagramm } from './Diagramm';

export function Facade(): JSX.Element {
  const [content, setContent] = useState<string>('demo');

  function clickMenu(value: string): void {
    setContent(value);
  }

  return (
    <div>
      <section className="designPatterns-facade-section">
        <div className="wrapper">
          <div className="designPatterns-facade-content">
            <div className="designPatterns-facade-heading-wrapper">
              <div className="designPatterns-facade-heading-wrapper-child-1">
                <h4 className="designPatterns-facade-heading">
                  Das Facade Entwurfsmuster
                </h4>
              </div>
              <div className="designPatterns-facade-heading-wrapper-child-2">
                <p
                  onClick={clickMenu.bind('', 'demo')}
                  className="designPatterns-facade-heading-1 links-designPatterns"
                >
                  Demo
                </p>
              </div>
              <div className="designPatterns-facade-heading-wrapper-child-3">
                <p
                  onClick={clickMenu.bind('', 'diagramm')}
                  className="designPatterns-facade-heading-2  links-designPatterns"
                >
                  Diagramm
                </p>
              </div>
              <div className="designPatterns-facade-heading-wrapper-child-4">
                <a href={pdf} target="_blank" rel="noreferrer">
                  <i className="icon-designPatterns-file-pdf links-icons-designPatterns"></i>
                </a>
              </div>
            </div>

            <p className="designPatterns-facade-content-1">
              Das Facade Pattern ist ein Software-Entwurfsmuster, es sieht die
              Definition eines zentralen Facade-Objekts vor was dann mit den
              Teilobjekten kommuniziert. Es bietet eine einheitliche
              Schnittstelle zu einer Menge von Schnittstellen eines Subsystems.
              Zum Beispiel kommunizieren die verschiedenen Clients eines
              Shopsystems (Web App, Handy App, Sprachsteuerung) mit dem
              Facade-Objekt und diese wiederum mit den einzelnen Untersystemen
              (Rechnungsstelle, Lager, Versand). Bei Änderungen muss dann nur
              noch das Facade-Objekt angepasst werden und nicht mehr die
              jeweiligen Clients.
            </p>
          </div>
        </div>
      </section>
      {content === 'demo' ? <Demo /> : null}
      {content === 'diagramm' ? <Diagramm /> : null}
    </div>
  );
}
