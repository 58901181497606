import '../../../css/startPage/contentIntroSection.css';
import logoFine from '../../../public/source_fine.png';
import logoBold from '../../../public/source_bold.png';

export function ContentIntroSection(): JSX.Element {
  return (
    <section className="content-intro-section">
      <div className="wrapper">
        <img
          className="img-section-img"
          src={logoFine}
          srcSet={logoBold + ' 2x, ' + logoBold + ' 3x'}
          alt=""
        ></img>
      </div>
    </section>
  );
}
