import { ApiReturn, ApiUsefulInformation } from '../interfaces/APIDataModel';

export abstract class RestApiUsefulInformation {
    static formUrl: string = 'https://www.jens-geyer.de/usefulInformation/16833365664636';

    static async readForm(): Promise<ApiReturn<ApiUsefulInformation>> {

        let errorText: string = '';
        const urlResponseForm: string = this.formUrl;
        const responseForm: void | Response = await fetch(urlResponseForm,
            {
                method: 'POST',
                body: JSON.stringify({
                    name: 'jensgeyer',
                    mail: 'no'
                })
            }).catch(function(error) {
                errorText = error;
              });
        
        if (responseForm && responseForm.ok === true && responseForm.status === 200) {
            return { error: '', content: await responseForm.json()};
        }
        
        if (responseForm) {
            return { error: responseForm.statusText };
        }

        return { error: errorText };
    }
}