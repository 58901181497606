import { useState } from 'react';
import '../../../../css/workSamplesPage/designPatterns/designPatternsMenu.css';

export interface NavigationProps {
  clickMenu: (value: string) => void;
  content: string;
}

export function DesignPatternsMenu(props: NavigationProps): JSX.Element {
  const [menuClassState, setClassState] = useState<string>(
    'designPatterns-menubutton'
  );
  const [menuPlaceState, setMenuPlaceState] =
    useState<string>('designPatterns');
  const [menuComponent, setMenuComponent] = useState<string>('');

  function menu(): void {
    if (menuClassState === 'designPatterns-menubutton') {
      setClassState('designPatterns-menubutton designPatterns-show-menu');
    } else {
      setClassState('designPatterns-menubutton');
    }
  }

  function clickMenu(value: string): void {
    setMenuPlaceState(value);
    if (menuClassState !== 'designPatterns-menubutton') {
      setClassState('designPatterns-menubutton');
    }
    props.clickMenu(value);
  }

  function mouseOverMenu(value: string): void {
    setMenuComponent(value);
  }

  function mouseOutMenu(): void {
    setMenuComponent('');
  }

  if (menuPlaceState !== props.content) {
    setMenuPlaceState(props.content);
  }

  return (
    <div>
      <button onClick={menu} className={menuClassState}>
        <i className="icon-menu"></i>&nbsp;&nbsp;Design-Patterns-Menü
      </button>
      <ul className="designPatterns-navigation-list">
        <li>
          <span className="designPatterns-navigation-list-h1">
            - Behavioral Patterns -
          </span>
        </li>
        <li className="designPatterns-navigation-list-li">
          <span
            onClick={clickMenu.bind('', 'command')}
            onMouseOver={mouseOverMenu.bind('', 'command')}
            onMouseOut={mouseOutMenu}
            className={
              menuPlaceState === 'command'
                ? 'designPatterns-navigation-list-span designPatterns-you-are-here'
                : 'designPatterns-navigation-list-span'
            }
          >
            {menuComponent === 'command' ? 'Command' : 'Command'}
          </span>
        </li>
        <li className="designPatterns-navigation-list-li">
          <span
            onClick={clickMenu.bind('', 'observer')}
            onMouseOver={mouseOverMenu.bind('', 'observer')}
            onMouseOut={mouseOutMenu}
            className={
              menuPlaceState === 'observer'
                ? 'designPatterns-navigation-list-span designPatterns-you-are-here'
                : 'designPatterns-navigation-list-span'
            }
          >
            {menuComponent === 'observer' ? 'Observer' : 'Observer'}
          </span>
        </li>
        <li className="designPatterns-navigation-list-li">
          <span
            onClick={clickMenu.bind('', 'state')}
            onMouseOver={mouseOverMenu.bind('', 'state')}
            onMouseOut={mouseOutMenu}
            className={
              menuPlaceState === 'state'
                ? 'designPatterns-navigation-list-span designPatterns-you-are-here'
                : 'designPatterns-navigation-list-span'
            }
          >
            {menuComponent === 'state' ? 'State' : 'State'}
          </span>
        </li>
        <li className="designPatterns-navigation-list-li">
          <span
            onClick={clickMenu.bind('', 'strategy')}
            onMouseOver={mouseOverMenu.bind('', 'strategy')}
            onMouseOut={mouseOutMenu}
            className={
              menuPlaceState === 'strategy'
                ? 'designPatterns-navigation-list-span designPatterns-you-are-here'
                : 'designPatterns-navigation-list-span'
            }
          >
            {menuComponent === 'strategy' ? 'Strategy' : 'Strategy'}
          </span>
        </li>
        <li className="designPatterns-navigation-list-li">
          <span
            onClick={clickMenu.bind('', 'visitor')}
            onMouseOver={mouseOverMenu.bind('', 'visitor')}
            onMouseOut={mouseOutMenu}
            className={
              menuPlaceState === 'visitor'
                ? 'designPatterns-navigation-list-span designPatterns-you-are-here'
                : 'designPatterns-navigation-list-span'
            }
          >
            {menuComponent === 'visitor' ? 'Visitor' : 'Visitor'}
          </span>
        </li>
        <li>
          <span className="designPatterns-navigation-list-h1">
            - Creational Patterns -
          </span>
        </li>
        <li className="designPatterns-navigation-list-li">
          <span
            onClick={clickMenu.bind('', 'abstractFactory')}
            onMouseOver={mouseOverMenu.bind('', 'abstractFactory')}
            onMouseOut={mouseOutMenu}
            className={
              menuPlaceState === 'abstractFactory'
                ? 'designPatterns-navigation-list-span designPatterns-you-are-here'
                : 'designPatterns-navigation-list-span'
            }
          >
            {menuComponent === 'abstractFactory'
              ? 'Abstract Factory'
              : 'Abstract Factory'}
          </span>
        </li>
        <li className="designPatterns-navigation-list-li">
          <span
            onClick={clickMenu.bind('', 'builder')}
            onMouseOver={mouseOverMenu.bind('', 'builder')}
            onMouseOut={mouseOutMenu}
            className={
              menuPlaceState === 'builder'
                ? 'designPatterns-navigation-list-span designPatterns-you-are-here'
                : 'designPatterns-navigation-list-span'
            }
          >
            {menuComponent === 'builder' ? 'Builder' : 'Builder'}
          </span>
        </li>
        <li className="designPatterns-navigation-list-li">
          <span
            onClick={clickMenu.bind('', 'factoryMethod')}
            onMouseOver={mouseOverMenu.bind('', 'factoryMethod')}
            onMouseOut={mouseOutMenu}
            className={
              menuPlaceState === 'factoryMethod'
                ? 'designPatterns-navigation-list-span designPatterns-you-are-here'
                : 'designPatterns-navigation-list-span'
            }
          >
            {menuComponent === 'factoryMethod'
              ? 'Factory Method'
              : 'Factory Method'}
          </span>
        </li>
        <li className="designPatterns-navigation-list-li">
          <span
            onClick={clickMenu.bind('', 'singleton')}
            onMouseOver={mouseOverMenu.bind('', 'singleton')}
            onMouseOut={mouseOutMenu}
            className={
              menuPlaceState === 'singleton'
                ? 'designPatterns-navigation-list-span designPatterns-you-are-here'
                : 'designPatterns-navigation-list-span'
            }
          >
            {menuComponent === 'singleton' ? 'Singleton' : 'Singleton'}
          </span>
        </li>
        <li>
          <span className="designPatterns-navigation-list-h1">
            - Structural Patterns -
          </span>
        </li>
        <li className="designPatterns-navigation-list-li">
          <span
            onClick={clickMenu.bind('', 'composite')}
            onMouseOver={mouseOverMenu.bind('', 'composite')}
            onMouseOut={mouseOutMenu}
            className={
              menuPlaceState === 'composite'
                ? 'designPatterns-navigation-list-span designPatterns-you-are-here'
                : 'designPatterns-navigation-list-span'
            }
          >
            {menuComponent === 'composite' ? 'Composite' : 'Composite'}
          </span>
        </li>
        <li className="designPatterns-navigation-list-li">
          <span
            onClick={clickMenu.bind('', 'decorator')}
            onMouseOver={mouseOverMenu.bind('', 'decorator')}
            onMouseOut={mouseOutMenu}
            className={
              menuPlaceState === 'decorator'
                ? 'designPatterns-navigation-list-span designPatterns-you-are-here'
                : 'designPatterns-navigation-list-span'
            }
          >
            {menuComponent === 'decorator' ? 'Decorator' : 'Decorator'}
          </span>
        </li>
        <li className="designPatterns-navigation-list-li">
          <span
            onClick={clickMenu.bind('', 'facade')}
            onMouseOver={mouseOverMenu.bind('', 'facade')}
            onMouseOut={mouseOutMenu}
            className={
              menuPlaceState === 'facade'
                ? 'designPatterns-navigation-list-span designPatterns-you-are-here'
                : 'designPatterns-navigation-list-span'
            }
          >
            {menuComponent === 'facade' ? 'Facade' : 'Facade'}
          </span>
        </li>
      </ul>
    </div>
  );
}
