import '../css/header.css';
import logo from '../public/logo.svg';
import logo2 from '../public/logo2.svg';

export function Header(): JSX.Element {
  return (
    <header className="page-header wrapper-min-width">
      <div className="wrapper">
        <img
          className="page-logo"
          src={logo}
          alt="Jens Geyer"
          width="222"
          height="33"
        ></img>
        <img
          className="page-slogan"
          src={logo2}
          alt="Jens Geyer"
          width="222"
          height="15"
        ></img>
      </div>
    </header>
  );
}
