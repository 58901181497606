import '../../../../../../css/workSamplesPage/designPatterns/behavioralPatterns/command/demo.css';

export function Demo(): JSX.Element {
  return (
    <section className="designPatterns-command-demo-section">
      <div className="wrapper">
        <div className="designPatterns-command-demo-content">Command demo</div>
      </div>
    </section>
  );
}
