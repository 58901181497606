import '../../../css/mePage/meInfoSection.css';

export function MeInfoSection(): JSX.Element {
  return (
    <section className="me-info-section">
      <div className="wrapper">
        <h4 className="font-color-heading-above">
          Qualitätsmerkmale der Anwendungsentwicklung
        </h4>

        <div className="me-info-content-1">
          Der Standard ISO 25010 ist die internationale Norm für
          Qualitätskriterien von Software, IT-Systemen und Software-Engineering.
        </div>
        <div className="me-info-content-1">
          Das Ziel bei der Entwicklung von Software ist es, die acht
          Qualitätsmerkmale für Softwarequalität zu erfüllen.
        </div>
        <div className="me-info-content">
          <div className="me-info-content-1">
            <span className="me-info-span">- Funktionalität:</span> Eine gute
            Software muss funktional sein und vollständig hinsichtlich der
            Softwarefunktionen.
          </div>
          <ul>
            <li>vollständig hinsichtlich Softwarefunktionen</li>
            <li>funktional korrekt</li>
            <li>angemessene Funktionalität</li>
          </ul>
        </div>

        <div className="me-info-content">
          <div className="me-info-content-1">
            <span className="me-info-span">- Zuverlässigkeit:</span> Eine
            verlässliche Software ist ausgereift, fehlertolerant, verfügbar und
            wiederherstellbar.
          </div>
          <ul>
            <li>ausgereifte Softwarequalität</li>
            <li>Verfügbarkeit</li>
            <li>Fehlertoleranz</li>
            <li>Wiederherstellbarkeit</li>
          </ul>
        </div>

        <div className="me-info-content">
          <div className="me-info-content-1">
            <span className="me-info-span">- Effizienz:</span> Die Performance
            der Software soll effizient und zeitsparend arbeiten und die
            Ressourcen effektiv nutzen.
          </div>
          <ul>
            <li>gutes Zeitverhalten</li>
            <li>Ressourcen effektiv nutzen</li>
            <li>Kapazitäten schonen</li>
          </ul>
        </div>

        <div className="me-info-content">
          <div className="me-info-content-1">
            <span className="me-info-span">- Sicherheit:</span> Eine Software
            muss die Richtlinien des Datenschutzes erfüllen, darf nicht
            manipulierbar sein und muss authentifizierbar sein.
          </div>
          <ul>
            <li>Datenschutz</li>
            <li>Integrität</li>
            <li>nicht manipulierbar</li>
            <li>sichere Administration und geschützte Benutzer-Accounts</li>
            <li>Authentizierbarkeit</li>
          </ul>
        </div>

        <div className="me-info-content">
          <div className="me-info-content-1">
            <span className="me-info-span">- Komparabilität:</span> Die Software
            soll eine optimale Co-Existenz zu weiteren Softwares und optimale
            Interoperabilität mitbringen.
          </div>
          <ul>
            <li>optimale Co-Existenz zu weiterer Software</li>
            <li>Interoperabilität</li>
          </ul>
        </div>

        <div className="me-info-content">
          <div className="me-info-content-1">
            <span className="me-info-span">- Usability:</span> Die Software soll
            sich durch eine gute Bedienbarkeit auszeichnen. Sie muss leicht zu
            erlernen sein und eine für den Nutzer ästhetische Oberfläche bieten.
          </div>
          <ul>
            <li>optimale Erkennbarkeit</li>
            <li>leicht erlernbar und lernfähig</li>
            <li>gute Bedienbarkeit</li>
            <li>Schutz vor Fehlbedienung durch den Nutzer</li>
            <li>Ästetisches User-Interface</li>
            <li>leichter Zugang</li>
          </ul>
        </div>

        <div className="me-info-content">
          <div className="me-info-content-1">
            <span className="me-info-span">- Wartung:</span> Eine einfache
            Wartung und ein modularer Aufbau erleichtern die Aktualisierbarkeit
            der Software.
          </div>
          <ul>
            <li>modularer Aufbau</li>
            <li>wiederverwendbare Komponenten</li>
            <li>gute Analyse-Funktion</li>
            <li>leicht modifizierbar</li>
            <li>umfangreiche Testoptionen</li>
          </ul>
        </div>

        <div className="me-info-content">
          <div className="me-info-content-1">
            <span className="me-info-span">- Portabilität:</span> Unter einer
            leichten Portabilität versteht man die leichte Adaptivität,
            Austauschbarkeit und Installation der Software.
          </div>
          <ul>
            <li>gute Adaptivität</li>
            <li>leicht zu installieren</li>
            <li>einfach austauschbar</li>
          </ul>
        </div>
      </div>
    </section>
  );
}
