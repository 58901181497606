import { useState } from 'react';
import './css/basis.css';
import './css/font_style.css';

import { Header } from './view/Header';
import { Navigation } from './view/Navigation';
import { Footer } from './view/Footer';
import { StartPage } from './view/content/StartPage';
import { WorkSamplesPage } from './view/content/WorkSamplesPage';
import { ContactPage } from './view/content/ContactPage';
import { MePage } from './view/content/MePage';
import { Info } from './view/content/Info';
import { Datenschutz } from './view/content/Datenschutz';

export function App(): JSX.Element {
  const [content, setContent] = useState<string>('startPage');

  function clickMenuNavigation(value: string): void {
    scrollToTop();
    setContent(value);
  }

  function clickMenuStartPage(value: string): void {
    scrollToTop();
    setContent(value);
  }

  function scrollToTop(): void {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  return (
    <div className="app">
      <Header />
      <Navigation clickMenu={clickMenuNavigation} content={content} />
      {content === 'startPage' ? (
        <StartPage clickMenu={clickMenuStartPage} />
      ) : null}
      {content === 'workSamplesPage' ? <WorkSamplesPage /> : null}
      {content === 'contactPage' ? <ContactPage /> : null}
      {content === 'mePage' ? <MePage clickMenu={clickMenuStartPage} /> : null}
      {content === 'info' ? <Info /> : null}
      {content === 'datenschutz' ? <Datenschutz /> : null}
      <Footer clickMenu={clickMenuStartPage} />
    </div>
  );
}
