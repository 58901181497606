import '../../../../../../css/workSamplesPage/designPatterns/creationalPatterns/factoryMethod/diagramm.css';
import allgemein from '../../../../../../public/designPatterns/factoryMethod/allgemein.svg';
import demo from '../../../../../../public/designPatterns/factoryMethod/demo.svg';

export function Diagramm(): JSX.Element {
  return (
    <section className="designPatterns-factoryMethod-diagramm-section">
      <div className="wrapper">
        <div className="designPatterns-factoryMethod-diagramm-content">
          <span className="designPatterns-factoryMethod-diagramm-slogan">
            UML Diagramm (Factory Method Pattern allgemein)
          </span>
          <img
            className="designPatterns-factoryMethod-diagramm-logo"
            src={allgemein}
            alt="designPatterns-factoryMethod-diagramm"
            width="265"
            height="179"
          ></img>
          <span className="designPatterns-factoryMethod-diagramm-slogan-2">
            UML Diagramm (Factory Method Pattern Demo)
          </span>
          <img
            className="designPatterns-factoryMethod-diagramm-logo-2"
            src={demo}
            alt="designPatterns-factoryMethod-diagramm"
            width="265"
            height="283"
          ></img>
        </div>
      </div>
    </section>
  );
}
