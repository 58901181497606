import '../../../../../../css/workSamplesPage/designPatterns/behavioralPatterns/strategy/diagramm.css';
import allgemein from '../../../../../../public/designPatterns/strategy/allgemein.svg';
import demo from '../../../../../../public/designPatterns/strategy/demo.svg';

export function Diagramm(): JSX.Element {
  return (
    <section className="designPatterns-strategy-diagramm-section">
      <div className="wrapper">
        <div className="designPatterns-strategy-diagramm-content">
          <span className="designPatterns-strategy-diagramm-slogan">
            UML Diagramm (Strategy Pattern allgemein)
          </span>
          <img
            className="designPatterns-strategy-diagramm-logo"
            src={allgemein}
            alt="designPatterns-strategy-diagramm"
            width="265"
            height="179"
          ></img>
          <span className="designPatterns-strategy-diagramm-slogan-2">
            UML Diagramm (Strategy Pattern Demo)
          </span>
          <img
            className="designPatterns-strategy-diagramm-logo-2"
            src={demo}
            alt="designPatterns-strategy-diagramm"
            width="265"
            height="283"
          ></img>
        </div>
      </div>
    </section>
  );
}
