import '../../css/mePage.css';
import { DatenschutzSection } from './datenschutz/Datenschutz';

export function Datenschutz(): JSX.Element {
  return (
    <main className="page-content">
      <section className="me-distance2 wrapper-min-width"></section>
      <DatenschutzSection />
    </main>
  );
}
