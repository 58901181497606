import '../../../css/startPage/infoBoxesSection.css';

export interface InfoBoxesSectionProps {
  clickMenu: (value: string) => void;
}

export function InfoBoxesSection(props: InfoBoxesSectionProps): JSX.Element {
  function clickMenu(value: string): void {
    props.clickMenu(value);
  }

  return (
    <section className="info-boxes-section wrapper-min-width">
      <div className="wrapper">
        <div className="boxes">
          <div className="box">
            <i
              onClick={clickMenu.bind('', 'workSamplesPage')}
              className="icon-workSamples links-icons"
            ></i>
            <h3 className="box-heading">Arbeitsproben</h3>
            <p className="box-content">
              hier gibt es einige Beispiele meiner bisher geleisteten Arbeit
            </p>
            <span
              onClick={clickMenu.bind('', 'workSamplesPage')}
              className="links-standard"
            >
              meine Arbeitsproben
            </span>
          </div>
          <div className="box">
            <i
              onClick={clickMenu.bind('', 'mePage')}
              className="icon-me links-icons"
            ></i>
            <h3 className="box-heading">Über mich</h3>
            <p className="box-content">
              hier gibt es mehr Info's über mich und meine Erfahrungen
            </p>
            <span
              onClick={clickMenu.bind('', 'mePage')}
              className="links-standard"
            >
              mehr Informationen
            </span>
          </div>
          <div className="box">
            <i
              onClick={clickMenu.bind('', 'contactPage')}
              className="icon-contact links-icons"
            ></i>
            <h3 className="box-heading">Kontakt</h3>
            <p className="box-content">
              hier gibt es die Möglichkeit mit mir in Kontakt zu treten
            </p>
            <span
              onClick={clickMenu.bind('', 'contactPage')}
              className="links-standard"
            >
              zum Kontakt
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}
