import '../../../css/contactPage/contactMapSection.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

export function ContactMapSection(): JSX.Element {
  return (
    <div className="contact-map-content">
      <span className="contact-map-span-heading">Auf der Karte:</span>
      <div className="contact-map-map">
        <MapContainer
          center={[50.8351, 10.94454]}
          zoom={13}
          scrollWheelZoom={false}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[50.8351, 10.94454]}>
            <Popup>
              Jens Geyer
              <br />
              Klausstraße 17
              <br />
              99310 Arnstadt
            </Popup>
          </Marker>
        </MapContainer>
      </div>
    </div>
  );
}
