import '../../../../../../css/workSamplesPage/designPatterns/behavioralPatterns/observer/demo.css';

export function Demo(): JSX.Element {
  return (
    <section className="designPatterns-observer-demo-section">
      <div className="wrapper">
        <div className="designPatterns-observer-demo-content">
          Observer demo
        </div>
      </div>
    </section>
  );
}
